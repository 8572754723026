.wrapper_loading{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    top: 0;
    left: 0;
    background: #F9F9F9;
    padding: 10px;
}
.wrapper_loading.wrapper_loading_overdue{
    animation: loading 2s ease .0s 1 forwards;
}

@keyframes loading {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        visibility: hidden;
    }
}

.container {
    width: 80px;
    height: 100px;
}

.block {
    position: relative;
    box-sizing: border-box;
    float: left;
    margin: 0 10px 10px 0;
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background: #3a3a3a;
}

.block:nth-child(4n+1) { animation: wave 2s ease .0s infinite; }
.block:nth-child(4n+2) { animation: wave 2s ease .2s infinite; }
.block:nth-child(4n+3) { animation: wave 2s ease .4s infinite; }
.block:nth-child(4n+4) { animation: wave 2s ease .6s infinite; margin-right: 0; }

@keyframes wave {
    0%   { top: 0;     opacity: 1; }
    50%  { top: 30px;  opacity: .2; }
    100% { top: 0;     opacity: 1; }
}


