.wrapper{
    max-width: 1400px;
    width: 100%;
    padding: 30px 0;
}
.menu_phone_but,.menu_phone{
    display: none;
    z-index: 2;
}
.wrapper_logo img{
    width: 90px;
    height: 100px;
    margin-right: 30px;
}
.menu{
    display: none;
}
.text_menu{
    white-space: nowrap;
    transition: all .3s;
}
.text_menu:hover{
    transform: translateY(-2px);
}
.wrapper_logo{
    display: flex;
    color: #303030;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
}
.wrapper_navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.wrapper_navbar a{
    color: #303030;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}
.connetion{
    display: inline;
    border-radius: 10px;
    background: #349EFF;
    padding: 8px 22px;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.logo_text_phone{
    display: none;
}

@media (max-width: 1200px) {
    .wrapper{
        padding: 30px 30px;
    }
    .menu_phone_but{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 20px;
    }
    .wrapper_navbar{
        display: flex;
        justify-content: end;
        align-items: center;
    }
    .wrapper_navbar a{
        display: none;
    }
    .menu a{
        display: inline;
    }
    .menu{
        display: block;
    }
    .menu_phone a{
        display: block;
        text-align: center;
        margin: 20px auto;
    }
    .menu_phone{
        display: block;
        width: 100%;
        background: #FFF;
        position: absolute;
        top: 0;
        right: 0;
    }
    .menu_phone_but input
    {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    }
    span{
        display: flex;
        width: 29px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: #303030;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 5px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    span:first-child
    {
    transform-origin: 0% 0%;
    }
    span:nth-last-child(2)
    {
    transform-origin: 0% 100%;
    }
    input:checked ~ span
    {
    opacity: 1;
    transform: rotate(45deg) translate(0px, 0px);
    background: #36383F;
    }
    input:checked ~ span:nth-last-child(3)
    {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
    }
    input:checked ~ span:nth-last-child(2)
    {
    transform: rotate(-45deg) translate(-1px, 1px);
    }
    .menu
    {
    position: absolute;
    width: 100%;
    box-shadow: 0 0 10px #85888C;
    margin: -50px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;
    top: 0;
    right: 0;
    background-color: #F5F6FA;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(0%, -100%);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    ul {
        padding: 0;
        list-style-type: none;
      }
    li
    {
    padding: 10px 0;
    transition-delay: 2s;
    }
    .menu_phone_but input:checked ~ .menu{
        transform: translate(0%, 0%);
    }
    .text_title{
        white-space: nowrap;
    }
}
@media (max-width: 768px) {
    .logo_text{
        display: none;
    }
    .logo_text_phone{
        display: block;
        font-size: calc(24px + 16 * (100vw / 1280)) !important;
    }
    .logo_text_phone span{
        text-align: center;
        background: none !important;
        white-space: nowrap;
    }
}