
.generalBlocks{
  padding: 20px 20px;
  margin: 20px 0;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 28.6px -7px rgba(0, 0, 0, 0.25);
  max-width: 687px;
  min-height: 215px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.generalBlocks_inner{
  max-width: 597px;

}
.generalBlockslabel{
  color: #303030;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.generalBlocks_title{
  color: #303030;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom:10px;
}
.generalBlocks_svg{
  margin-bottom: 3px;
  margin-left: 10px;
}