.wrapper{
    background: #F9F9F9;
    padding: 0  10px!important;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}