.wrapper{
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
    user-select: none;
    padding: 0px;
    margin: 0px auto;
    position: relative;
    flex-grow: 1;
}
.down_subtitle{
    color: white;
}
.wrapper_address,.wrapper_who{
    background: #fff;
    border-radius: 10px;
    padding: 5px 10px;
    color: #303030;
    display: flex;
}
.icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon img{
    width: 38px;
    height: 38px;
    cursor: pointer;
}
.block_item{
    display:flex;
    align-items: center;
    margin-top: 39px;

}
.block_item_h1_text{
    color: #303030;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
.block_item_h1{
    display: flex;
    align-items: center;
}
.block_item_h1_svg{
    margin-top:2px;
    margin-left: 10px;
}

.block_item_under_text{
    color: #303030;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
.text_href{
    color: #303030;
}
.left{
    margin-right:10px !important;
}

.block_item_inner{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 28.6px -7px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 42px !important;
    min-height: 173px;
    padding: 20px 40px;
    height: 100%;
}
.right{
    margin-top: 10px;
}
.news_card{
    background: #303030;
    border-radius: 20px;
    color: white;
    max-width: 520px;
}
h4.news_card_title{
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 156.119%;
    margin: auto;
}
.main_image{
    width: 100%;
    height: auto;
    border-radius: 20px;
    background: url('../../images/background.png') center center/cover no-repeat;
    padding: 100px 50px;
    margin: 50px 0;
}
.main_image h1{
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 156.119%;
    max-width: 330px;
}
.pict{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: url('https://i.postimg.cc/8PHMy5fc/photo-2023-11-01-19-48-02-2.png') 0 0/auto 100% no-repeat;
}
@media (max-widthL:768px) {
    .wrapper_pict{
        display: none;
    }
}
@media (max-width: 600px) {
    .wrapper{
        padding: 30px;
    }
    .main_image{
        justify-content: center;
        margin: 0px auto 50px auto;
        padding: 50px 15px;
    }
}