.wrapper{
    width: 100%;
    user-select: none;
    padding: 30px 10px;
    margin: 0px auto;
    position: relative;
}
.contant_email{
    color: #303030;
}
.left{
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    color: #303030;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.right{
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;
}
.icons{
    display: flex;
    justify-content: end;
    align-items: center;
}
.telegram{
    cursor: pointer;
    width: 30px;
    height: 30px;
}
.whatsapp{
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-right: 5px;
}
.signature{
    margin-top: 20px;
    color: #303030;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: end;
}