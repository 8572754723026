
.highlighted button{
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  border-radius: 10px;
  background: #349EFF;

  }
  
  .button {
    margin-top:10px;
    line-height: normal;
    border-radius: 10px;
    box-shadow: 0px 0px 28.6px -7px rgba(0, 0, 0, 0.25);
    border:0;
    flex-shrink: 0;
    background: #FFF;
    height: 60px;
    width: 100%;
    color:#303030;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }
  
  .block {
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
  }